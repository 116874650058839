<template>
  <div class="profile">
    <!-- Header -->
    <section class="head">
      <div class="left">
        <a @click="goTo('/home')">
          <font-awesome-icon
            icon="fa-solid fa-chevron-left"
            size="lg"
          ></font-awesome-icon>
        </a>
      </div>
      <span>我的帳號</span>
      <div class="right"></div>
    </section>
    <!-- Main -->
    <section class="main">
      <BarcodePopup :isPop="false" />
      <section class="list account">
        <a @click="goTo('/profile/edit')">
          <font-awesome-icon
            icon="fa-solid fa-pen"
            size="lg"
          ></font-awesome-icon>
          <span>編輯帳號</span>
          <font-awesome-icon
            icon="fa-solid fa-chevron-right"
          ></font-awesome-icon>
        </a>
        <!-- <a @click="goTo('/code-exchange')"> -->
        <!-- <a>
          <font-awesome-icon
            icon="fa-solid fa-ticket"
            size="lg"
          ></font-awesome-icon>
          <span>序號兌換</span>
          <font-awesome-icon
            icon="fa-solid fa-chevron-right"
          ></font-awesome-icon>
        </a> -->
        <!-- <a>
              <font-awesome-icon icon="fa-solid fa-clipboard-list" size="lg"></font-awesome-icon>
              <span>消費紀錄</span>
              <font-awesome-icon icon="fa-solid fa-chevron-right"></font-awesome-icon>
          </a> -->
        <!-- <a @click="goTo('/carrier/edit')">
          <font-awesome-icon
            icon="fa-solid fa-receipt"
            size="lg"
          ></font-awesome-icon>
          <span>手機載具</span>
          <font-awesome-icon
            icon="fa-solid fa-chevron-right"
          ></font-awesome-icon>
        </a> -->
      </section>
      <div class="line"></div>
      <section class="list setting">
        <!-- <a>
              <font-awesome-icon icon="fa-solid fa-bell" size="lg"></font-awesome-icon>
              <span>通知</span>
              <label class="switch">
                  <input type="checkbox" checked>
                  <span class="slider round"></span>
              </label>
          </a> -->
        <!-- <a @click="openCategoryPop">
          <font-awesome-icon
            icon="fa-solid fa-language"
            size="lg"
          ></font-awesome-icon>
          <span>語言</span>
          <span>繁體中文</span>
          <font-awesome-icon
            icon="fa-solid fa-chevron-right"
          ></font-awesome-icon>
        </a> -->
        <!-- <a>
              <font-awesome-icon icon="fa-solid fa-circle-question" size="lg"></font-awesome-icon>
              <span>常見問題</span>
              <font-awesome-icon icon="fa-solid fa-chevron-right"></font-awesome-icon>
          </a> -->
        <a @click="goTo('/policy')">
          <font-awesome-icon
            icon="fa-solid fa-shield-halved"
            size="lg"
          ></font-awesome-icon>
          <span>隱私權政策</span>
          <font-awesome-icon
            icon="fa-solid fa-chevron-right"
          ></font-awesome-icon>
        </a>
        <a @click="goTo('/policy')">
          <font-awesome-icon
            icon="fa-solid fa-file-lines"
            size="lg"
          ></font-awesome-icon>
          <span>服務條款</span>
          <font-awesome-icon
            icon="fa-solid fa-chevron-right"
          ></font-awesome-icon>
        </a>
      </section>
      <span class="remark">
        v{{ version }} <br />版權 © 2022 DOTDOT Inc. - All Rights Reserved</span
      >
    </section>

    <!-- 分類popup -->
    <!-- <section id="popCategory" class="pop category" @click="closeCategoryPop">
      <div class="popBody" @click.stop>
        <a class="close" @click="closeCategoryPop"
          ><font-awesome-icon
            icon="fa-solid fa-xmark"
            size="lg"
          ></font-awesome-icon
        ></a>
        <span class="title">語言</span>
        <a><span class="active">中文</span></a>
        <a><span>English</span></a>
        <a><span>日本語</span></a>
      </div>
    </section> -->
  </div>
</template>

<script>
import BarcodePopup from '@/components/BarcodePopup.vue';

export default {
  name: 'Profile',
  components: {
    BarcodePopup,
  },
  data() {
    return {
      apiHost: process.env.VUE_APP_API_HOST,
      isCopied: false,
      appHost: process.env.VUE_APP_HOST + process.env.BASE_URL,
      barcodePopupOpen: false,
    };
  },
  computed: {
    version() {
      return process.env.PACKAGE_VERSION;
    },
    enableStamp() {
      return process.env.VUE_APP_ENABLE_STAMP === 'true';
    },
  },
  methods: {
    goTo(path) {
      this.$router.push({ path, query: this.$route.query });
    },
    copy() {
      let textToCopy = document.querySelector(`#referral-code`);
      console.log('referralCode: ', textToCopy.value);
      textToCopy.setAttribute('type', 'text');
      textToCopy.select();
      var successful = document.execCommand('copy');
      if (successful) {
        this.isCopied = true;
      }
      textToCopy.setAttribute('type', 'hidden');
    },
    logout() {
      qcsso
        .logout({
          logoutRedirectUri: this.appHost.substring(0, this.appHost.length - 1),
        })
        .then(() => console.log('success'))
        .catch(err => console.log(err));
    },
    openCategoryPop() {
      popCategory.style.display = 'flex';
    },
    closeCategoryPop() {
      popCategory.children[0].style.animationName = 'popdown';
      setTimeout(() => {
        popCategory.style.display = 'none';
        popCategory.children[0].style.animationName = 'popup';
      }, 300);
    },
  },
};
</script>
